import './groups.scss';
import Gallery from "../common/Gallery";

function Groups({data}) {
    const groups = () => {
        const result = [];
        for (let i = 0; i< data.content.length; i+=2) {
            result[i] = (
                <div key={i}>
                    <div className="name">
                        <img className="pictureLeft" src={`/images/grupy/${data.content[i].image}`} alt=''></img>
                        <span className="groupName">{data.content[i].content}</span>
                    </div>
                   <div>
                       <Gallery id={data.content[i+1].id} folder={data.content[i+1].folder} files={data.content[i+1].items}></Gallery>
                   </div>
                </div>
            )
        }
        return result;
    }
    return (
        <div className="groups">
            <h1 className="title">{data.title}</h1>
            {groups()}
            <div className="spacing-bottom"></div>
        </div>
    );
}

export default Groups;