import {useState} from 'react';
import service from '../services/service';
import './adminPanel.scss';
import EditNews from "../admin/EditNews";
import EditActivities from "../admin/EditActivities";
import EditArchive from "../admin/EditArchive";
import EditHowTo from "../admin/EditHowTo";
import EditGroups from "../admin/EditGroups";
import EditReverse from "../admin/EditReverse";
import EditDocs from "../admin/EditDocs";
import EditParents from "../admin/EditParents";

const TYPES = {
    NEWS: 'NEWS',
    ACTIVITY: 'ACTIVITY',
    GROUPS: 'GROUPS',
    DOCS: 'DOCS',
    ARCHIVE: 'ARCHIVE',
    PARENTS: 'PARENTS',
    HOWTO: 'HOWTO',
    REVERSE: 'REVERSE'
}

function AdminPanel({user, setUser, data, setData}) {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [selectedTab, setSelectedTab] = useState(TYPES.HOWTO);

    const tabs = [
        {
            title: 'Aktualności',
            type: TYPES.NEWS
        },
        {
            title: "Z życia przedszkolaka",
            type: TYPES.ACTIVITY
        },
        {
            title: "Grupy i wychowawcy",
            type: TYPES.GROUPS
        },
        {
            title: "Dokumenty",
            type: TYPES.DOCS
        },
        {
            title: "Dla rodziców",
            type: TYPES.PARENTS
        },
        {
            title: 'Akcje archiwum',
            type: TYPES.ARCHIVE
        }
    ];

    const drawContent = () => {
        if (user.loggedIn) {
            return drawAdminPanel();
        } else {
            return drawLoginForm();
        }
    };

    const drawAdminPanel = () => {
        return (
            <div className="adminPanel">
                <div className="admin_head">
                    <button onClick={logoutFn}>Wyloguj się</button>
                    <br/><br/>
                    <button onClick={publishData}>Opublikuj zmiany</button>
                    <br/><br/>
                    <button onClick={reverseChange}>Cofnij zmiany</button>
                </div>
                {drawEditableContent()}
            </div>
        )
    }

    const drawTabs = () => {
        return tabs.map((item, index) => {
            return (
                <button className={item.type === selectedTab ? 'active' : ''} key={index}
                        onClick={setSelectedTab.bind(this, item.type)}>{item.title}</button>
            );
        });
    };

    const drawEditableContent = () => {
        return (
            <div>
                <div className="tabsPanel">
                    {drawTabs()}
                </div>
                {drawSelectedTab()}
            </div>
        )
    };

    const drawSelectedTab = () => {
        switch (selectedTab) {
            case TYPES.NEWS:
                return (<EditNews data={data} setData={setData}></EditNews>)
                break;
            case TYPES.ACTIVITY:
                return (<EditActivities data={data} setData={setData}></EditActivities>)
                break;
            case TYPES.ARCHIVE:
                return (<EditArchive data={data} setData={setData}></EditArchive>)
                break;
            case TYPES.HOWTO:
                return (<EditHowTo data={data} setData={setData}></EditHowTo>)
                break;
            case TYPES.GROUPS:
                return (<EditGroups data={data} setData={setData} user={user}></EditGroups>)
                break;
            case TYPES.REVERSE:
                return (<EditReverse data={data} setData={setData} user={user}></EditReverse>)
                break;
            case TYPES.DOCS:
                return (<EditDocs data={data} setData={setData}></EditDocs>)
                break;
            case TYPES.PARENTS:
                return (<EditParents data={data} setData={setData}></EditParents>)
                break;
        }
    };

    const logoutFn = () => {
        service.logout(user.token, setUser);
    };

    const publishData = () => {
        service.saveData(user.token, data, setUser);
    };

    const reverseChange = () => {
        setSelectedTab(TYPES.REVERSE);
    }

    const loginFn = () => {
        service.login(login, password, setUser);
    };

    const drawLoginForm = () => {
        return (
            <div className="adminPanelLogin">
                <table>
                    <tr>
                        <td>
                            Nazwa użytkownika:
                        </td>
                        <td>
                            <input type="text" onChange={(el) => setLogin(el.target.value)}></input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Hasło:
                        </td>
                        <td>
                            <input type="password" onChange={(el) => setPassword(el.target.value)}></input>
                        </td>
                    </tr>
                </table>
                <button onClick={loginFn}>Zaloguj się</button>
            </div>
        )
    };

    return (
        drawContent()
    );
}

export default AdminPanel;