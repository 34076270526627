import "./Footer.scss";
import {Link} from "react-router-dom";

function Footer({theme, changeTheme, setFontSize}) {
    const changeFont = (option, event) => {
        event.preventDefault();
        setFontSize(option)
    }

    const changeContrast = (event) => {
        event.preventDefault()
        changeTheme();
    }

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="localisation hide-mobile">
                    <i className="fa fa-map-marker"></i>
                    <a rel="noreferrer" target="_blank" href="https://www.google.pl/maps/place/Akacjowa+4,+23-206+Str%C3%B3%C5%BCa-Kolonia/@50.9011132,22.2797621,18.5z/data=!4m5!3m4!1s0x4722e7ed40f02375:0x2f07d44a48d3abab!8m2!3d50.9013768!4d22.2794017">
                        Stróża-Kolonia, ul. Akacjowa 4A
                    </a>
                </div>
                <div className="phone">
                    <i className="fa fa-phone"></i>
                    81-821-13-82
                </div>
                <div className="email hide-small-mobile">
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:przedszkole.stroza@vp.pl">przedszkole.stroza@vp.pl</a>
                </div>

                <div className="rightFooter flexRight">
                    <a className="bip" rel="noreferrer" href="https://przedszkolestroza.e-bip.eu" target="_blank">
                        <img src="/images/bip_logo.svg" alt="Biuletyn informacji publicznej"></img>
                    </a>
                    <a className="fontChanger hide-mobile" href="/" onClick={changeFont.bind(this, 'default')}>
                        <img className="normal" src="/images/text-size-normal.svg" alt="Normalna czcionka"></img>
                        <img className="contrast" src="/images/text-size-normal_contrast.svg" alt="Normalna czcionka"></img>
                    </a>
                    <a className="fontChanger hide-mobile" href="/" onClick={changeFont.bind(this, 'bigger')}>
                        <img className="normal" src="/images/text-size-large.svg" alt="Większa czcionka"></img>
                        <img className="contrast" src="/images/text-size-large_contrast.svg" alt="Większa czcionka"></img>
                    </a>
                  {/*  <a className="fontChanger" href="/" onClick={changeFont.bind(this, 'large')}>
                        <img src="../images/text-size-max.svg" alt="Największa czcionka"></img>
                    </a>*/}
                    <a className="highContrast contrast" href="/" onClick={changeContrast.bind(this)}>
                        <i className="fa fa-adjust"></i>
                    </a>
                </div>
            </div>
           {/* <div className="footer-author">
                <span>
                    ©2021 - Designed & Created by
                    <Link className="author" to="/author">
                        Jakub Wilk IT Consulting
                    </Link>
                </span>
            </div>*/}
        </div>
    )
}

export default Footer