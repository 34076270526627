import crypto from 'crypto';
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const login = (login, password, setUser) => {

    const pwHash = crypto.createHash("sha256").update(password).digest('hex');
    axios.post(`${url}/login`, {
        login: login,
        password: pwHash
    }).then(res => {
        if (res.status === 200 && res.data.token) {
            setUser({
                loggedIn: true,
                token: res.data.token
            });
            window.sessionStorage.setItem('token', res.data.token);
        }
    }).catch(err => {console.error('Api not working', err)});
}

const logout = (token, setUser) => {
    axios.post(`${url}/logout`, {
        token: token
    }).then(res => {
        if (res.status === 200) {
            setUser({
                loggedIn: false,
                token: ''
            });
            window.sessionStorage.removeItem('token');
        }
    }).catch(err => {console.error('Api not working', err)});
}

const getData = (setResponses) => {
    axios.get(`${url}/getData`).then((res) => {
       setTimeout(() => {
           setResponses(res.data, 3);
       }, 100);
    });
}

const saveData = (token, data) => {
    axios.post(`${url}/sendChanges`, {
        token: token,
        data: data
    }).then(res => {
       if (res.status === 200) {
            window.alert('Udało się opublikować zamiany');
       }
    }).catch(err => {
        if (err.status === 403) {
            window.alert('Brak autoryzacji, sesja wygasła, zaloguj się ponownie');
        }
        console.error('Api not working', err);
    });
}

const getRetrievalData = (token, setFolders) => {
    axios.post(`${url}/getRetrievalData`, {
        token: token
    }).then(res => {
       if (res.status === 200) {
           return setFolders(res.data);
       }
    }).catch(err => {console.error('Api not working', err)});
}

const getIconsList = (token, setIcons) => {
    axios.post(`${url}/getAvailableIcons`, {
        token: token
    }).then(res => {
       if (res.status === 200) {
           return setIcons(res.data);
       }
    }).catch(err => {console.error('Api not working', err)});
}

const reverseData = (token, date, setReversed) => {
    axios.post(`${url}/reverseData`, {
        token: token,
        date: date
    }).then(res => {
       if (res.status === 200) {
           return setReversed(true);
       }
    }).catch(err => {console.error('Api not working', err)});
}

const result = {
    login,
    logout,
    saveData,
    getIconsList,
    reverseData,
    getData,
    getRetrievalData
}

export default result

