import './editDocs.scss';
import {useEffect, useState} from "react";
import moment from "moment";

const url = process.env.REACT_APP_API_URL;

function EditDocs({data, setData}) {
    const [indexChangingFile, setIndexChangingFile] = useState(-1);
    const [dropzoneAdded, setDropzoneAdded] = useState(false);
    const [nameChangingFile, setNameChangingFile] = useState('');
    let dropzone;
    useEffect(() => {
        if (indexChangingFile === -1 && dropzone !== null) {
            dropzone = null;
            setDropzoneAdded(false);
        }
        if (!dropzoneAdded && indexChangingFile !== -1) {
            setTimeout(() => {
                dropzone = new window.Dropzone('#addPhotos', {url: `${url}/uploadFile/3/`});
                dropzone.on('addedfile', (file) => {
                    setChangedFile(file, indexChangingFile, nameChangingFile);
                    setTimeout(() => {
                        dropzone = null;
                        setDropzoneAdded(false);
                        setIndexChangingFile(-1);
                        setNameChangingFile('');
                    }, 1500);
                })
            });
            setDropzoneAdded(true);
        }
    }, [indexChangingFile]);

    const setChangedFile = (file, index, name) => {
        const copy = {...data};
        const newFileName = `${moment().year()}_${moment().month()}_${moment().day()}_${file.upload.filename}`;
        copy[name].content[index].fileName = newFileName;
        setData(copy);
    }

    const changeTitle = (newVal, name) => {
        const copy = {...data};
        copy[name].title = newVal;
        setData(copy);
    };

    const changeDocumentTitle = (newVal, name, index) => {
        const copy = {...data};
        copy[name].content[index].name = newVal;
        setData(copy);
    };

    const addNewFile = (name) => {
        const copy = {...data};
        copy[name].content.push({
            type: "document",
            fileName: "",
            styles: "",
            name: ""
        });
        setData(copy);
    };

    const drawSection = (docs, name) => {
        return (
            <div className={'section'}>
                <input
                    size="50" type="text"
                    value={docs.title}
                    onChange={(el) => changeTitle(el.target.value, name)}>
                </input>
                <br/>
                <br/>
                <div>
                    {drawDocuments(name)}
                </div>
                <br/>
                <div>
                    <button onClick={addNewFile.bind(this, name)}>
                        Dodaj nowy dokument
                    </button>
                </div>
            </div>
        )
    };

    const removeFile = (name, index) => {
        const copy = {...data};
        copy[name].content.splice(index, 1);
        setData(copy);
    }

    const changeFile = (name, index) => {
        setNameChangingFile(name);
        setIndexChangingFile(index);
    }

    const closeChangingFile = () => {
        setNameChangingFile('');
        setIndexChangingFile(-1);
    }

    const drawDocuments = (name) => {
        return data[name].content.map((doc, index) => <div key={index} className={'doc'}>
            <div>
                <input
                    size="80" type="text"
                    value={data[name].content[index].name}
                    onChange={(el) => changeDocumentTitle(el.target.value, name, index)}>
                </input>
            </div>
            {!dropzoneAdded ? <div>
                <button onClick={removeFile.bind(this, name, index)}>Usuń plik</button>
                <button onClick={changeFile.bind(this, name, index)}>
                    {data[name].content[index].fileName ? 'Zamień plik' : 'Dodaj plik'}
                </button>
            </div>: ''}
            {index === indexChangingFile && name===nameChangingFile ?
                <div id="addPhotos" className="dropzone"></div> : ''}
            {index === indexChangingFile && name===nameChangingFile ?
                <button onClick={closeChangingFile.bind(this, -1)}>Zamknij dodawanie pliku</button> : ''}
        </div>);
    };

    return (<div className={'editDocs'}>
        <div>
            {drawSection(data.documents, 'documents')}
        </div>
        <div>
            {drawSection(data.recruitment, 'recruitment')}
        </div>
        <div>
            {drawSection(data.standards, 'standards')}
        </div>
    </div>)
}

export default EditDocs;