import _ from 'lodash';
import StyleSelector from "./StyleSelector";

function AddParagraph({item, setItem, index}) {

    const updateText = (value) => {
        const newVal = value.replace('\n', ' ');
        const itemCopy = _.cloneDeep(item);
        itemCopy.content = newVal;
        setItem(index, itemCopy);
    }
    return (
        <div>
            <div>
                {`Paragraf (${index + 1})`}
            </div>
            <StyleSelector item={item} setItem={setItem} index={index}></StyleSelector>
            {/*<input type="text" onChange={(ev) => updateParagraph(ev.target.value)} value={item.content}></input>*/}
            <textarea
                rows={Math.ceil(item.content.length/80)}
                cols="100"
                onChange={(ev) => updateText(ev.target.value)}
                value={item.content}>
            </textarea>
        </div>
    );
}

export default AddParagraph