import './LandingPage.scss';
import PhotoAnimation from "./PhotoAnimation";
import {Swiper, SwiperSlide} from "swiper/react";
import generatorUtils from "../utils/GeneratorUtils";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import SwiperCore, {
    Autoplay, Pagination, Navigation
} from 'swiper/core';
import {Link} from "react-router-dom";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

function LandingPage({data, lastArticle}) {
    const generateContent = (item) => {
        const result = [];
        item.content.forEach((contentItem, index) => {
            result[index] = generatorUtils.generateItem(contentItem, index);
        });
        return result
    }
    const newsData = data.content.filter(item => item.showOnMainPage).reverse()
    let news = newsData.map(item => {
        return (
            <SwiperSlide className="newsItem" key={item.id}>
                <Link className="newsMainPage" to={`/news/${item.id}`}>
                    {generateContent(item)}
                    <div className="date">{item.date}</div>
                </Link>
            </SwiperSlide>
        )
    });
    if (lastArticle) {
        const item = (<SwiperSlide className="newsItem" key={lastArticle.id}>
            <Link className="newsMainPage" to={`/activity/${lastArticle.id}`}>
                <h3>{lastArticle.title}</h3>
                <div className="center"><i className="fa fa-hand-o-right"></i>Przejdź do nowego wydarzenia z życia dzieciaków!</div>
                <div className="date">{lastArticle.date}</div>
            </Link>
        </SwiperSlide>);
        let index = news.length;
        if (lastArticle.date) {
            const dates = newsData.map(nd => nd.date);
            dates.push(lastArticle.date);
            dates.sort().reverse();
            index = dates.findIndex(el => el === lastArticle.date);
        }
        news.splice(index, 0, item);
    }

    const showSwiper = data.content.filter(item => item.showOnMainPage).length > 1;
    return (
        <div className="landingPage">
            <PhotoAnimation></PhotoAnimation>
            <div className="av_title">
                <img className="hide-mobile" src="/images/przedszkole.png" alt="Przedszkole w Stróży"></img>
                <img className="show-mobile-only" src="/images/przedszkole_xs.png" alt="Przedszkole w Stróży"></img>
            </div>
            <div className="swiperContainer">
                <Swiper
                    slidesPerView={1}
                    autoplay={{
                        "delay": 7000,
                        "disableOnInteraction": true
                    }}
                    spaceBetween={30}
                    loop={showSwiper}
                    pagination={showSwiper ? {
                        "clickable": true
                    } : {}}
                    navigation={false} className="mySwiper">
                    {news}
                </Swiper>
            </div>
        </div>
    )
}

export default LandingPage