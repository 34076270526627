import {useState} from "react";
import AddNews from "./AddNews";

function EditNews({data, setData}) {
    const [isAction, setIsAction] = useState('');

    const addNews = () => {
        setIsAction(true);
    };

    const drawAddingPanel = () => {
        if (isAction) {
            return (<AddNews save={saveNews} cancel={cancel}></AddNews>);
        }
    };

    const showMenu = () => {
        if (!isAction) {
            return (
                <div>
                    <button onClick={addNews}>Dodaj wpis do Aktualności</button>
                </div>
            )
        }
    };

    const cancel = () => {
        setIsAction(false);
    };

    const saveNews = (newNews) => {
        const copy = {...data};
        copy.news.content.push(newNews);
        setData(copy);
        setIsAction(false);
    };

    const showCurrentNews = () => {
        if (!isAction) {
            return data.news.content.map((el, index) =>
                <div key={index}>
                    {el.content[0].content[0] ? el.content[0].content[0] : el.content[1].content[0]}
                    <button onClick={removeNews.bind(this, index)}>Usuń</button>
                </div>
            );
        }
    };

    const removeNews = (index) => {
        const copy = {...data};
        copy.news.content.splice(index, 1);
        setData(copy);
    };

    return (<div>
        <div>
            {showCurrentNews()}
            {showMenu()}
            {drawAddingPanel()}
        </div>
    </div>)
}

export default EditNews;