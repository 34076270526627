import './Teachers.scss';
import generatorUtils from "../utils/GeneratorUtils";

function Teachers({data}) {
    const teachers = () => {
        const result = [];
        for (let i = 3; i < data.content.length; i += 4) {
            result[i - 3] = (<div className="teachers_row" key={i}>
                <div className="teachers_col col-left">
                    { data.content[i].image && (
                        <img src={`/images/grupy/${data.content[i].image}`} alt=''></img>
                    )}
                    <div className="teachers_display">
                        {generatorUtils.generateItem(data.content[i], i)}
                        {data.content[i + 1] ? generatorUtils.generateItem(data.content[i + 1], i + 1) : ''}
                    </div>
                </div>
                { data.content[i + 2] && (
                    <div className="teachers_col col-right">
                        <div className="teachers_display">
                            {data.content[i + 2] ? generatorUtils.generateItem(data.content[i + 2], i + 2) : ''}
                            {data.content[i + 3] ? generatorUtils.generateItem(data.content[i + 3], i + 3) : ''}
                        </div>
                        { data.content[i+2].image && (
                            <img src={`/images/grupy/${data.content[i+2].image}`} alt=''></img>
                        )}
                    </div>
                )}
            </div>);
        }
        return result;
    }
    return (
        <div className="teachers">
            <h1 className="title">{data.title}</h1>
            {generatorUtils.generateItem(data.content[0]) /* Principal header */}
            {generatorUtils.generateItem(data.content[1]) /* Principal */}
            {generatorUtils.generateItem(data.content[2]) /* Teachers header */}
            <div className="teachers_section">
                {teachers()}
            </div>
        </div>
    );
}

export default Teachers