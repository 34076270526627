import './PhotoAnimation.scss';
import {useState, useEffect} from 'react';
import landingPageImages from '../data/landingPageImages';

function PhotoAnimation() {
    const [active, setActive] = useState(false);
    const [noMargin, setNoMargin] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [images, setImages] = useState([...landingPageImages.children]);
    const [counter, setCounter] = useState(0);

    const changePhotos = (part, targetPhotos) => {
        [0,2,4,6,8].forEach(i => {
            images[i+part] = targetPhotos[i+part];
        });
        setImages(images);
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            const step = counter % 9;
            const oddIteration = (counter%18) < 9;
            if (counter === 0 || step === 0) {
                setActive(true);
                setTimeout(() => {
                    const newPhotos = oddIteration ? [...landingPageImages.building] : [...landingPageImages.building2];
                    changePhotos(0, newPhotos);
                },2500)
            }
            if (step === 1) {
                setActive(active => !active);
                setTimeout(() => {
                    const newPhotos = oddIteration ? [...landingPageImages.building] : [...landingPageImages.building2];
                    changePhotos(1, newPhotos);
                },2500)
            }
            if (step === 2) {
                setTimeout(() => {
                    setHidden(hidden => !hidden);
                },2500);
            }
            if (step === 3 || (counter % 9) === 4) {
                setNoMargin(noMargin => !noMargin);
            }
            if (step === 5) {
                setHidden(hidden => !hidden);
            }
            if (step === 6) {
                const newPhotos = oddIteration ? [...landingPageImages.children] : [...landingPageImages.children2];
                changePhotos(1, newPhotos);
                setTimeout(() => {
                    setActive(active => !active);
                },500)
            }
            if (step === 7) {
                const newPhotos = oddIteration ? [...landingPageImages.children] : [...landingPageImages.children2];
                changePhotos(0, newPhotos);
                setTimeout(() => {
                    setActive(active => !active);
                },2500)
            }
            if (step === 8) {
                setTimeout(() => {
                    setActive(active => !active);
                },2500)
            }

            setCounter(counter + 1);
        }, [2, 5, 6, 7].includes(counter%9) ? 3000 : 5000);
        return () => {
            clearTimeout(timeout);
        }
    }, [counter]);
    const addSquare = (index) => {
        return (
            <img className={`color c${index / 2} 
            ${active ? 'active' : ''} 
            ${hidden ? 'hidden' : ''}
            `} src={`../images/rect_${index / 2}.svg`}
                 alt="kolor"></img>
        );
    };
    const addImage = (image, rowNumber) => {
        return (
            <img className={`
            ${noMargin ? 'noMargin' : ''} 
            row${rowNumber}
            ${image.isBuilding ? 'fill' : ''}
            `} src={image.url} alt={image.alt}></img>
        );
    };
    const addRow = (rowNumber) => {
        const list = images.slice((rowNumber-1)*5, (rowNumber*5)).map((listItem, index)=> {
            return (
                <div className={`photoItem item${index+1}`} key={listItem.url}>
                    {addImage(listItem, rowNumber)}
                    {((index + (rowNumber-1)*5)%2 !== 0) ? addSquare((index+1)*2) : ''}
                </div>
            )
        });
        return (
            <div className="row">
                {list}
            </div>
        );
    };
    return (
        <div className="photoAnimation hide-mobile">
            {addRow(1)}
            {addRow(2)}
        </div>
    )
}

export default PhotoAnimation;

