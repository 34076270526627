import './News.scss';
import {useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import PageContent from "../common/PageContent";

function News({data}) {
    let {id} = useParams();
    const [showAll, setShowAll] = useState(data.content.length < 999);
    useEffect(() => {
        if (id) {
            const element = document.getElementById(`article_${id}`)
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: 'center'});
            }
        }
    }, []);

    const showNews = () => {
        let news = data.content.slice().reverse();
        if (!showAll) {
            news = data.content.slice().reverse().slice(0,2);
        }
        return news.map((item) => {
           return (
               <div key={item.id} id={item.id} className="one-news">
                   <img className="pin pin-left" src="/images/pin-left.png"></img>
                   <img className="pin pin-right" src="/images/pin-right.png"></img>
                   <PageContent data={item}></PageContent>
                   {item.date ? <div className="date">({item.date})</div> : ''}
               </div>
           );
        });
    }

    const showALlEntries = (event) => {
        event.preventDefault();
        setShowAll(true);
    }

    return (
        <div className="news">
            {/*<div className="pagetitle">{data.title}</div>*/}

            <div className="board">
                <img className="newsImg" src="/images/aktualnosci.png" alt="Aktualności"></img>
                {showNews()}
            </div>
            {!showAll ?
                <div className="showALl">
                    <a href="/" onClick={(ev) => showALlEntries(ev)}>Pokaż wszystkie</a>
                </div>
            : ''}
        </div>
    );
}

export default News