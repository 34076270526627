import {useState} from "react";
import AddActivity from "./AddActivity";
import _ from 'lodash';
import './editActivities.scss';
import moment from "moment";

function EditActivities({data, setData}) {
    const [editForm, setEditForm] = useState(false);
    const [addingNew, setAddingNew] = useState(false);
    const [articleCopy, setArticleCopy] = useState({});
    const [editingArticleIndex, setEditingArticleIndex] = useState(null);
    const [content, setContent] = useState([]);

    const getDefaultYear = () => {
        if (moment().month() >= 8) {
            return `${moment().year()}${moment().year()+1}`;
        } else {
            return `${moment().year()-1}${moment().year()}`;
        }
    };

    const removeArticle = () => {
        if (window.confirm("Czy na pewno chcesz usunąć ten wpis?") !== true) {
            return;
        }
        const dataCopy = _.cloneDeep(data);
        dataCopy.activity.content.splice(editingArticleIndex, 1);
        setData(dataCopy);
        setEditingArticleIndex(-1);
        setEditForm(false);
        setContent([]);
    };

    const addActivity = () => {
        const newId = Date.now();
        const newEntry = {
            id: newId,
            title: '',
            year: getDefaultYear(),
            styles: 'no-top-padding justify',
            date: moment().format('YYYY-MM-DD'),
            content: []
        }
        const dataCopy = _.cloneDeep(data);
        dataCopy.activity.content.push(newEntry);
        setData(dataCopy);
        const index = dataCopy.activity.content.findIndex(el => el.id === newId);
        setContent(_.cloneDeep(dataCopy.activity.content[index]));
        setEditingArticleIndex(index);
        setEditForm(true);
        setAddingNew(true);
    }

    const cancel = () => {
        const dataCopy = _.cloneDeep(data);
        if (addingNew) {
            dataCopy.activity.content.splice(editingArticleIndex, 1);
            setAddingNew(false);
        } else {
            if (editingArticleIndex >=0) {
                dataCopy.activity[editingArticleIndex] = _.cloneDeep(articleCopy);
                setArticleCopy({});
            }
        }
        setData(dataCopy);
        setContent([]);
        setEditingArticleIndex(-1);
        setEditForm(false);
    }

    const saveActivity = (editedActivity) => {
        if (addingNew) {
            setAddingNew(false);
        } else {

        }
        const dataCopy = _.cloneDeep(data);
        dataCopy.activity.content[editingArticleIndex] = editedActivity;
        setData(dataCopy);
        setContent([]);
        setEditingArticleIndex(-1);
        setEditForm(false);
    }

    const drawAddingPanel = () => {
        if (editForm) {
            return (<AddActivity save={saveActivity} cancel={cancel} activity={content} setActivity={setContent} removeArticle={removeArticle}></AddActivity>);
        }
    }

    const editActivity = (event, index) => {
        event.preventDefault();
        setArticleCopy(_.cloneDeep(data.activity.content[index]));
        setEditingArticleIndex(index);
        setContent(_.cloneDeep(data.activity.content[index]));
        setEditForm(true);
    }

    const drawList = () => {
        if (!editForm) {
            return (
                <>
                    <div className="activitiesList">
                        <div>Możesz edytować istniejące wpisy klikając w link poniżej:</div>
                        {data.activity.content.map((el, index) => {
                            return (
                                <div key={index}>
                                    <a
                                        href="#"
                                        onClick={(ev) => editActivity(ev, index)}
                                    >
                                        {el.title ? el.title : '\<bez tytułu\>'}
                                    </a>
                                </div>
                            )
                        })}
                    </div>
                </>
            );
        }
    }

    const drawActions = () => {
        if (!editForm) {
            return (
                <button className="actionBtn" onClick={addActivity}>Dodaj nowy wpis do "Z życia przedszkolaka"</button>)
        }
    }

    return (
        <div className="editActivities">
            {data.activity.currentYear === data.archive.data[0].year ?
                <h1>Uwaga, w archiwum i w "Z życia przedszkolaka" są te same artykuły - przejdź do "Akcje archiwum" żeby
                    zacząć nowy rok</h1> : ''}
            {drawActions()}
            {drawAddingPanel()}
            {drawList()}
        </div>
    );
}

export default EditActivities;