import {useEffect, useState} from "react";
import _ from 'lodash';
import AddContent from "./AddContent";
import AddPhotos from "./AddPhotos";
import './addActivity.scss';

const AddActivity = ({save, cancel, activity, setActivity, removeArticle}) => {
    const [content, setContent] = useState(activity.content);

    const [gallery, setGallery] = useState({
        type: 'gallery',
        id: `gal${activity.id}`,
        folder: `/zdjecia/${activity.year}/${activity.id}/`,
        items: []
    });

    const setTitle = (value) => {
        const copy = _.cloneDeep(activity);
        copy.title = value;
        setActivity(copy);
    };

    const allowedList = {
        text: true,
        paragraph: true
    };

    const localSave = () => {
        const copy = _.cloneDeep(activity);
        copy.content = content;
        const galleryIndex = copy.content.findIndex(el => el.type === 'gallery');
        if (galleryIndex !== -1) {
            copy.content[galleryIndex].items.push(...gallery.items);
        } else {
            copy.content.push(gallery);
        }
        setActivity(copy);
        save(copy);
    }

    return (
        <div className="addActivity">
            <div className="head">Dodawanie artykułu do 'Z życia przedszkolaka'</div>
            <div>
                <span className={'title'}>Tytuł:</span>
                <input
                    size="100"
                    type="text"
                    value={activity.title}
                    onChange={(el) => setTitle(el.target.value)
                    }/>
            </div>
            <div>
                Rok: {`${activity.year.slice(0,4)}/${activity.year.slice(4)}`}
            </div>
            <div>
                Dodaj treść:
                <AddContent content={content} setContent={setContent} availableItems={allowedList}></AddContent>
            </div>
            <div>
                Dodaj zdjęcia:
                <AddPhotos gallery={gallery} setGallery={setGallery} id={activity.id} year={activity.year}></AddPhotos>
            </div>
            <button onClick={localSave}>Zapisz</button>
            <button onClick={cancel.bind(this)}>Anuluj</button>
            <button onClick={removeArticle.bind(this)}>Usuń ten wpis</button>
        </div>
    );
}

export default AddActivity;