import GenerateContent from "./GenerateContent";
import './ArticleBox.scss';
import {useState} from "react";

function ArticleBox({article}) {
    const [showArticle, setShowArticle] = useState(false);
    const toggleReadMore = (event) => {
        event.preventDefault();
        if (showArticle) {
            setTimeout(() => {
                const element = document.getElementById(`an_article_${article.id}`)
                element.scrollIntoView({behavior: "smooth", block: 'start'});
            }, 1000);
        }
        setShowArticle(!showArticle);
    }

    return (
        <div id={`an_article_${article.id}`} key={article.title} className="articleBox">
            <h2 className="articleTitle">{article.title}</h2>
            <GenerateContent id={article.id} showArticle={showArticle} contentToGenerate={article.content}></GenerateContent>
            <div className="moreLink">
                <a href="/" onClick={toggleReadMore.bind(this)}>{showArticle ? article.linkHide : article.link}</a>
            </div>
        </div>
    );
}

export default ArticleBox;