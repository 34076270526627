import './App.scss';
import {useEffect, useState, useRef} from 'react';
import {HashRouter} from 'react-router-dom';
import AppRoutes from "./AppRoutes";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import service from "./services/service";
import moment from "moment";

function App() {
    const [data, setData] = useState(undefined);
    const refData = useRef(0);
    const [user, setUser] = useState({
        loggedIn: !!window.sessionStorage.getItem('token'),
        token: window.sessionStorage.getItem('token')
    });
    const [theme, setTheme] = useState('default');
    const [fontSize, setFontSize] = useState('default');

    const setResponses = (newData, priority) => {
        if (refData.current < priority) {
            setData(newData);
            refData.current = priority
        }
    }

    const month = moment().month();
    const day = moment().date();
    const hour = moment().hour();

    useEffect(() => {
        fetch('/combinedData.json', {cache: "no-store"}).then((res) => {
            return res.json();
        }).then(staticData => {
            setResponses(staticData, 1);
        });
        fetch(`/combinedData${month}${day}${hour}.json`, {cache: "no-store"}).then((res) => {
            return res.json();
        }).then(staticData => {
            setResponses(staticData, 2);
        });
        service.getData(setResponses);
    }, [])

    const changeTheme = () => {
        setTheme(theme === 'default' ? 'contrast' : 'default');
    }

    return (
        <div className={`App theme-${theme} font-${fontSize}`}>
            <div className="App-container">
                <HashRouter>
                    <Header user={user}></Header>
                    <AppRoutes data={data} user={user} setUser={setUser} setData={setData}></AppRoutes>
                    <Footer theme={theme} changeTheme={changeTheme} setFontSize={setFontSize}></Footer>
                </HashRouter>
            </div>
        </div>
    );
}

export default App;
