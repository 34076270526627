import Nav from '../data/navigationData';
import './Navigation.scss'
import {Link} from "react-router-dom";
import $ from 'jquery';

function Navigation({showMobile, isMobileMenuShown, setIsMobileMenuShown}) {


    const showMobileMenu = (event) => {
        if(event && !showMobile) {
            event.preventDefault();
        }
        if (isMobileMenuShown) {
            $('.mobile-dropdown-content').hide();
            $('.content').show();
            $('.footer').show();
        } else {
            $('.mobile-dropdown-content').show();
            $('.content').hide();
            $('.footer').hide();
        }
        setIsMobileMenuShown(!isMobileMenuShown);
    }
    const createItem = (el) => {
        switch (el.type) {
            case Nav.TYPES.LINK:
            return (
                <Link className={`
                    ${el.mobileOnly ? 'show-mobile-only ': ' '}
                    ${el.showMobile ? 'show-mobile ' : 'hide-mobile '}
                    ${el.showTablet ? 'show-tablet ' : 'hide-tablet '}
                `}
                      id={el.id} to={el.href} key={el.name}>
                    {el.name}
                </Link>
            );
            case Nav.TYPES.EXTERNAL_LINK:
                return (
                    <a className={`
                    ${el.mobileOnly ? 'show-mobile-only ': ' '}
                    ${el.showMobile ? 'show-mobile ' : 'hide-mobile '}
                    ${el.showTablet ? 'show-tablet ' : 'hide-tablet '}
                `}
                       id={el.id} href={el.href} key={el.id}>{el.name}</a>
                );
            case Nav.TYPES.DROPDOWN_ITEM:
                return (
                    <Link className="dropItem"
                          to={el.href} key={el.name}><span>{el.name}</span></Link>
                );
            case Nav.TYPES.DROPDOWN_MENU:
                return (
                    <div key={el.name} className={`dropdown
                        ${el.showMobile ? 'show-mobile ' : 'hide-mobile '}
                    `}
                         id={el.id}>
                        <a className="dropbtn" href="/" onClick={(event) => event.preventDefault()}>
                            {el.name}
                            <img src="/images/down-arrow.svg" alt="rozwiń"></img>
                        </a>
                        <div className="dropdown-content">
                            {el.items.map(item => createItem(item))}
                        </div>
                    </div>
                );
            case Nav.TYPES.MOBILE_HAM:
                return (
                    <div key={el.name} className="dropdown">
                        <a className={`${el.mobileOnly ? 'show-mobile-only': 'show-all'} mobile-ham`} href="/"
                           onClick={(event) => showMobileMenu(event)}>
                            <i className={`fa ${isMobileMenuShown ? 'fa-times' : 'fa-bars'} `}></i>
                        </a>
                    </div>
                );
            case Nav.TYPES.MOBILE_MENU_ITEM:
                return (
                    <Link className="dropItem" onClick={(ev) => showMobileMenu(ev)}
                          to={el.href} key={el.id}><span>{el.name}</span></Link>
                );
            case Nav.TYPES.MOBILE_MENU_ITEM_EXTERNAL:
                return (
                    <a className="dropItem" href={el.href}
                          to={el.href} key={el.id}><span>{el.name}</span></a>
                );
            default:
                return '';
        }
    }

    const navTemplateFn = () => {
        return Nav.navData.map(el => createItem(el));
    }

    const mobileMenuFn = () => {
        return Nav.navData.find(el => el.type === Nav.TYPES.MOBILE_HAM).items.map(item => createItem(item));
    }

    return (
        <div>
            {!showMobile ?
                <div className="navbar">
                    {navTemplateFn()}
                </div> :
                <div className="mobile-dropdown-content hide">
                    {mobileMenuFn()}
                </div>
            }

        </div>
    );
}

export default Navigation;