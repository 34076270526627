const landingPageImages = {
    building: Array.from(Array(10).keys()).map(index => {
        return {
            url: `/zdjecia/przedszkole/parts/image_part_0${(index < 9) ? '0' : ''}${index + 1}.jpg`,
            alt: 'zdjęcie',
            isBuilding: true
        }
    }),
    building2: Array.from(Array(10).keys()).map(index => {
        return {
            url: `/zdjecia/przedszkole/parts/image2_part_0${(index < 9) ? '0' : ''}${index + 1}.jpg`,
            alt: 'zdjęcie',
            isBuilding: true
        }
    }),
    children2: Array.from(Array(10).keys()).map(index => {
        return {
            url: `/zdjecia/strona_glowna/${index + 1}.jpg`,
            alt: 'zdjęcie',
            isBuilding: false
        }
    }),
    children: Array.from(Array(10).keys()).map(index => {
        return {
            url: `/zdjecia/strona_glowna/${index + 1}.jpg`,
            alt: 'zdjęcie',
            isBuilding: false
        }
    })
}

export default landingPageImages;