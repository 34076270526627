import AddParagraph from "./AddParagraph";
import AddText from "./AddText";
import AddBullets from './AddBullets';
import GaleryPreview from "./GaleryPreview";
import './addContent.scss';

let idNo = 0;

function AddContent({content, setContent, availableItems, style}) {

    const removeElement = (index) => {
        const copy = [...content];
        copy.splice(index, 1);
        setContent(copy);
    }


    const generateCurrentlyAddedItems = () => {
        const result = [];
        content.forEach((item, index) => {
            let form = ('');
            const commonParams = {
                key: item.id,
                index: index,
                item: item,
                setItem: setItem
            }
            switch (item.type) {
                case 'paragraph': {
                    form = (<AddParagraph {...commonParams}/>);
                    break;
                }
                case 'text': {
                    form = (<AddText {...commonParams} />);
                    break;
                }
                case 'bullets': {
                    form = (<AddBullets {...commonParams} />)
                    break;
                }
                case 'gallery': {
                    form = (<GaleryPreview {...commonParams} />);
                    break;
                }
                default:
                    break;
            }
            if (item.type !== 'gallery') {
                result.push(form);
            }
        });
        return result.map((el, i) => {
            return (
                <div className="fieldItem" key={i}>
                    {el}
                    <button onClick={() => removeElement(i)}>Usuń ten element</button>
                </div>
            );
        });
    }

    const setItem = (index, newValue) => {
        const copy = [...content];
        copy[index] = newValue
        setContent(copy);
    }

    const addParagraph = () => {
        const copy = [...content];
        copy.push({
            type: 'paragraph',
            styles: style ? style : '',
            id: Date.now(),
            content: []
        });
        setContent(copy);
    }

    const addText = () => {
        const copy = [...content];
        copy.push({
            type: 'text',
            id: Date.now(),
            styles: style ? style : '',
            content: []
        });
        setContent(copy);
    }

    const addSection = () => {
        const copy = [...content];
        copy.push({
            type: 'section',
            id: Date.now(),
            styles: 'center',
            content: []
        });
        setContent(copy);
    }

    const addBullets = () => {
        const copy = [...content];
        copy.push({
            type: 'bullets',
            id: Date.now(),
            styles: 'justify',
            content: ['']
        });
        setContent(copy);
    }

    const addGalery = () => {
        const copy = [...content];
        copy.push({
            type: 'gallery',
            id: Date.now(),
            styles: '',
            folder: '',
            items: []
        });
        setContent(copy);
    }

    const addFile = () => {
        const copy = [...content];
        copy.push({
            type: 'document',
            id: Date.now(),
            fileName: '',
            name: ''
        });
        setContent(copy);
    }

    const showGalleryIfExists = () => {
        const galleryIndex = content.findIndex(el => el.type === 'gallery');
        if (galleryIndex && galleryIndex !== -1) {
            return (
                <div className="fieldItem">
                    <GaleryPreview index={galleryIndex} item={content[galleryIndex]} setItem={setItem} />
                </div>
            );
        }
    };

    const generateAddItems = () => {
        return (
            <div>
                {availableItems.paragraph ? <button onClick={addParagraph}>Dodaj nowy paragraf</button> : ''}
                {availableItems.text ? <button onClick={addText}>Dodaj nowy tekst (z enterami)</button> : ''}
                {availableItems.section ? <button onClick={addSection}>Dodaj pod-nagłówek</button> : ''}
                {availableItems.bullets ? <button onClick={addBullets}>Nowa lista wypunktowana</button> : ''}
                {availableItems.gallery ? <button onClick={addGalery}>Dodaj galerie</button> : ''}
                {availableItems.documents ? <button onClick={addFile}>Dodaj plik</button> : ''}
            </div>
        )
    };

    return (
        <div className="addContent">
            {generateCurrentlyAddedItems()}
            {generateAddItems()}
            {showGalleryIfExists()}
        </div>
    )
}

export default AddContent