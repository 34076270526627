import _ from 'lodash';
import './galeryPreview.scss';

function GaleryPreview({item, setItem, index}) {

    const moveToTop = (i) => {
        const clone = _.cloneDeep(item);
        const copy = _.cloneDeep(clone.items[i]);
        clone.items.splice(i, 1);
        clone.items.unshift(copy);
        setItem(index, clone);
    }

    const removeImage = (i) => {
        const clone = _.cloneDeep(item);
        clone.items.splice(i, 1);
        setItem(index, clone);
    };

    return (
        <div className="galeryPreview">
            <div>
                <div>{`Pliki dodane do galerii`}</div>
                <div className="items">
                    {item.items.map((el, i) => {
                        return (
                            <div key={`item${i}`}>
                                <button onClick={() => removeImage(i)}>Usuń</button>
                                <button onClick={() => moveToTop(i)}>Przenieś na samą górę</button>
                                <img src={`${item.folder}${el.src}`}></img>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>);
}

export default GaleryPreview;