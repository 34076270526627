import _ from 'lodash';
import StyleSelector from "./StyleSelector";

function AddText({item, setItem, index}) {

    const updateText = (value) => {
        const newVal = value.split(/\n/);
        const itemCopy = _.cloneDeep(item);
        itemCopy.content = newVal;
        setItem(index, itemCopy);
    }

    const getValue = () => {
        let text = '';
        item.content.forEach((el, index) => {
            text += el;
            if (index !== item.content.length - 1) {
                text += '\n';
            }
        });
        return text;
    }

    return (
        <div>
            <div>
                {`Tekst (${index + 1})`}
            </div>
            <StyleSelector item={item} setItem={setItem} index={index}/>
            <textarea
                rows={item.content.length}
                cols="80"
                onChange={(ev) => updateText(ev.target.value)}
                value={getValue()}>
            </textarea>
        </div>
    );
}

export default AddText