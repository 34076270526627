import {useEffect, useState} from "react";
import './addPhotos.scss';

const url = process.env.REACT_APP_API_URL;

const AddPhotos = ({gallery, setGallery, id, year}) => {

    const [dropzoneAdded, setDropzoneAdded] = useState(false);

    let dropzone;

    useEffect(() => {
        if (!dropzoneAdded) {
            setTimeout(() => {
                window.Dropzone.prototype.defaultOptions.dictDefaultMessage = "Przesuń pliki lub naciśnij tutaj żeby wybrać"
                dropzone = new window.Dropzone('#addPhotos', {url: `${url}/uploadFile/1/${id}/${year}`});
                dropzone.on('addedfile', (file) => {
                    addPhoto(file.upload.filename);
                })
            });
            setDropzoneAdded(true);
        }

    }, []);

    const addPhoto = (filename) => {
        const galCopy = {...gallery};
        galCopy.items.push({
            src: filename,
            srct: filename,
            title: ''
        });
        setGallery(galCopy);
    }

    return (<div>
        <div id="addPhotos" className="dropzone"></div>
    </div>)
}

export default AddPhotos;