import './PageContent.scss';
import GenerateContent from "./GenerateContent";

function PageContent({data}) {
    return (
        <div className={`pageContent ${data.styles}`}>
            <h1 className="title">{data.title}</h1>
            <GenerateContent id={data.id} contentToGenerate={data.content}></GenerateContent>
        </div>
    );
}

export default PageContent;