import './Activities.scss';
import {useState, useEffect} from 'react';
import {useParams, useHistory } from 'react-router-dom';
import PageContent from "../common/PageContent";
import GenerateHands from "../common/GenerateHands";

function Activities({data, showHands, path}) {
    let {id} = useParams();
    let index;
    let history = useHistory();
    if (id) {
        index = data.content.findIndex(el => el.id === id);
    }
    const [currentArticleIndex, setCurrentArticleIndex] = useState(index && index !== -1 ? index : data.content.length - 1);
    useEffect (() => {
        if (currentArticleIndex >= 0) {
            history.push({
                pathname: `/${path}/${data.content[currentArticleIndex].id}`,
            });
        }

    }, [currentArticleIndex]);
    const showNext = () => {
        const id = data.content[currentArticleIndex].content.find(el => el.type === 'gallery').id;
        window.jQuery(`#${id}`).nanogallery2('destroy');
        setTimeout(() => {
            setCurrentArticleIndex(currentArticleIndex - 1);
        }, 0);
    }

    const showPrevious = () => {
        const id = data.content[currentArticleIndex].content.find(el => el.type === 'gallery').id;
        window.jQuery(`#${id}`).nanogallery2('destroy');
        setTimeout(() => {
            setCurrentArticleIndex(currentArticleIndex + 1);
        }, 0);
    }

    const generateArticle = () => {
        return (
            <div className="activities">
                <div className="title">{data.title}</div>
                <div className="activities-container">
                    <div className="navigation">

                        <button
                            className={`nextButton ${currentArticleIndex < (data.content.length - 1) ? '' : 'hide'}`}
                            onClick={showPrevious}>
                            <img src="/images/right-arrow.svg" alt="Nowsze wydarzenie"></img> <span>Nowsze</span>
                        </button>

                        {data.content[currentArticleIndex].name}
                        {
                            data.content.length > 0 ?
                                <button className="showAll" onClick={setCurrentArticleIndex.bind(this,-1)}>
                                    <span>&#x205D;&#x205D;&#x205D;</span> Wszystkie <span>&#x205D;&#x205D;&#x205D;</span>
                                </button> : ''
                        }

                        <button className={`previousButton ${currentArticleIndex > 0 ? '' : 'hide'}`}
                                onClick={showNext}>
                            <span>Starsze</span> <img src="/images/right-arrow.svg" alt="Starsze wydarzenie"></img>
                        </button>

                    </div>
                    <div className="activity_content">
                        <PageContent data={data.content[currentArticleIndex]}></PageContent>
                    </div>
                    <div className="date">
                        {data.content[currentArticleIndex].date ? `Dodano:  ${data.content[currentArticleIndex].date}` : ''}
                    </div>
                </div>
            </div>
        )
    }

    const openArticle = (id, ev) => {
        ev.preventDefault();
        setCurrentArticleIndex(id);
    };

    const generateTitles = () => {
        const result = [];
        data.content.forEach((item, index) => {
            result.push((
                <div key={item.id} className="listItem">
                    <a href='/' onClick={(e) => openArticle(index, e)}>{item.title}</a>
                </div>
            ))
        });
        return result;
    }

    const generateList = () => {
        return (
            <div className="activities activities_list">
                <div className="title">{data.title}</div>
                <div className="list">
                    {generateTitles()}
                </div>
            </div>
        )
    }

    return (
        <div className="actColumns">
            {showHands ? <div className="handsLeft hide-mobile">
                <GenerateHands isLeft={true} id={currentArticleIndex}></GenerateHands>
            </div>
                : ''
            }

            {
                currentArticleIndex >= 0 ? generateArticle() : generateList()
            }
            {showHands ? <div className="handsRight hide-mobile">
                    <GenerateHands isLeft={false} id={currentArticleIndex}></GenerateHands>
                </div>
                : ''
            }

        </div>
    );
};

export default Activities;