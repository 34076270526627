import _ from 'lodash';
import './styleSelector.scss';

function StyleSelector({item, setItem, index}) {

    let stylesSelected = [...item.styles.split(' ')];

    const styles = {
        Justify: 'justify',
        Bold: 'bold',
        LowerSpacings: 'lower-spacing',
        NoSpacingTop: 'no-top-spacing',
    }

    const updateStyle = () => {
        const copy = _.cloneDeep(item);
        copy.styles = stylesSelected.join(' ');
        setItem(index, copy);
    }

    const changeStyle = (el, style) => {
        const shouldBeThere = el.target.checked;
        if (shouldBeThere) {
            stylesSelected.push(style);
        } else {
            stylesSelected = stylesSelected.filter(el => el !== style);
        }
        updateStyle();
    }

    return (
    <div className="styleSelector">
        Style dla tego elementu: <span className="vertical-space-20"/>
        Pogrubienie
        <input
            type="checkbox"
            checked={stylesSelected.includes(styles.Bold)}
            onChange={(el) => changeStyle(el, styles.Bold)}
        />
        Justifikacja
        <input
            type="checkbox"
            checked={stylesSelected.includes(styles.Justify)}
            onChange={(el) => changeStyle(el, styles.Justify)}
        />
        Mniejsze odstepy (góra i dół)
        <input
            type="checkbox"
            checked={stylesSelected.includes(styles.LowerSpacings)}
            onChange={(el) => changeStyle(el, styles.LowerSpacings)}
        />
        Bez odstępu na górze
        <input
            type="checkbox"
            checked={stylesSelected.includes(styles.NoSpacingTop)}
            onChange={(el) => changeStyle(el, styles.NoSpacingTop)}
        />
    </div>);
}

export default StyleSelector;