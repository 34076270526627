import PageContent from '../common/PageContent';
import './Projects.scss';
import $ from 'jquery';
import {useEffect, useState} from 'react';

function Projects ({data}) {
    const [animationStarted, setAnimationStarted] = useState(false);

    let lastResult = 0
    const getRandomInt = (min, max, save) => {
        let result = 0;
        min = Math.ceil(min);
        max = Math.floor(max);
        const randomize = () => {
            result = Math.floor(Math.random() * (max - min)) + min;
        }
        if (save && lastResult) {
            while(Math.abs(lastResult - result) < 2000) {
                randomize();
            }
        } else {
            randomize();
        }
        return result;
    }

    const startAnimation = () => {
        setAnimationStarted(true);
        const hideMeRandomly = (id) => {
            const randHideTime = getRandomInt(5000, 10000, true);
            setTimeout(() => {
                $(id).addClass('hide_hearth');
                const randShowTime = getRandomInt(1000, 3000);
                setTimeout(() => {
                    $(id).removeClass('hide_hearth');
                    setTimeout(() => {
                        hideMeRandomly(id);
                    }, 5000);
                }, randShowTime+3000)
            }, randHideTime);
        }
        hideMeRandomly('#hearth1');
        hideMeRandomly('#hearth2');
        hideMeRandomly('#hearth3');
        hideMeRandomly('#hearth4');
    }

    useEffect(() => {
        if (!animationStarted) {
            startAnimation();
        }
    }, [animationStarted]);

    return (
        <div className="thanksPage">
            <PageContent data={data}></PageContent>
        </div>
    )
}

export default Projects;