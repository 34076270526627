import './howTo.scss';

function EditHowTo() {
    return (
        <div className="howTo">
            <h2>Jak to działa?</h2>
            <h3>Wszystkie zmiany które robisz, są robione tylko na Twoim komputerze póki nie naciśniesz "Opublikuj zmiany"</h3>
            <h3>Po zrobieniu zmian lub dodaniu artykułu możesz wejśc w odpowiednią zakładkę i zobaczyć jak wygąda strona po zmianach</h3>
            <h3>Jeśli coś się nie uda - wciśnij F5 (odśwież stronę) i zmiany których nie opublikowałaś znikną</h3>
            <h3>Jeśli zmiany wyglądają dobrze wróć na stronę Administracji (/#/admin) i wcisnij "Opublikuj zmiany"</h3>
            <h3>Po opublikowaniu zmian wszyscu będą mogli je zobaczyć na stronie przedszkola</h3>
            <h3>Jeśli opublikowane zmiany coś popsuły wybierz "Cofnij zmiany" po prawej stronie.</h3>
        </div>
    );
}

export default EditHowTo