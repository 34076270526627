import {useState, useEffect} from "react";
import './editGroups.scss';
import moment from 'moment';
import service from "../services/service";

const url = process.env.REACT_APP_API_URL;

function EditGroups({data, setData, user}) {

    const [indexAddingPhoto, setIndexAddingPhoto] = useState(-1);
    const [indexChangingIcon, setIndexChangingIcon] = useState(-1);
    const [dropzoneAdded, setDropzoneAdded] = useState(false);
    const [icons, setIcons] = useState([]);
    let dropzone;

    useEffect(() => {
        if (indexAddingPhoto === -1 && dropzone !== null) {
            dropzone = null;
            setDropzoneAdded(false);
        }
        if (!dropzoneAdded && indexAddingPhoto !== -1) {
            setTimeout(() => {
                dropzone = new window.Dropzone('#addPhotos', {url: `${url}/uploadFile/2/`});
                dropzone.on('addedfile', (file) => {
                    setGroupPhoto(file, indexAddingPhoto);
                    setTimeout(() => {
                        dropzone = null;
                        setDropzoneAdded(false);
                        setIndexAddingPhoto(-1);
                    }, 1500);
                })
            });
            setDropzoneAdded(true);
        }
    }, [indexAddingPhoto]);

    useEffect(() => {
        if (!icons.length) {
            service.getIconsList(user.token, setIcons);
        }
    }, [icons]);

    const changeGroupName = (val, index) => {
        const copy = {...data};
        copy.staff.content[index].content[0] = val;
        setData(copy);
    }

    const changeGroupNameWithDesc = (val, index) => {
        const realIndex = index * 2;
        const copy = {...data};
        copy.groups.content[realIndex].content[0] = val;
        setData(copy);
    }

    const changePrincipalName = (val) => {
        const copy = {...data};
        copy.staff.content[1].content[0] = val;
        setData(copy);
    }

    const changeTeacherName = (val, index, sIndex) => {
        const copy = {...data};
        copy.staff.content[index].content[sIndex] = val;
        setData(copy);
    }

    const setGroupPhoto = (file, index) => {
        const copy = {...data};
        copy.groups.content[index*2+1].items[0].src = `${moment().year()}_${file.upload.filename}`;
        copy.groups.content[index*2+1].items[0].srct = `${moment().year()}_${file.upload.filename}`;
        setData(copy);
    }

    const drawIcon = (drawChange, icon, index) => {
        return (<div>
            Ikonka: <img className="pictureLeft" style={{width: '50px'}} src={`/images/grupy/${icon}`} alt=''></img>
            {drawChange ? <button onClick={setIndexChangingIcon.bind(this, index)}>Zmień</button> : ''}
        </div>);
    }

    const drawStaff = (index) => {
        return data.staff.content[index].content.map((staffMember, sIndex) => (
            <div key={sIndex}>
                <input size="40"
                       value={staffMember}
                       type="text"
                       onChange={(el) => changeTeacherName(el.target.value, index, sIndex)}>
                </input>
            </div>
        ));
    }

    const drawChangePhoto = (index) => {
        return (<div>
            {indexAddingPhoto === -1 ?
                <button onClick={setIndexAddingPhoto.bind(this, index)}>Zmień zdjęcie grupowe</button> : ''}

            {index === indexAddingPhoto ?
                <div id="addPhotos" className="dropzone"></div> : ''
            }
            {index === indexAddingPhoto ?
                <button onClick={setIndexAddingPhoto.bind(this, -1)}>Zamknij dodawanie zdjecia</button> : ''
            }

        </div>)
    }

    const setNewIcon = (newIconName) => {
        const copy = {...data};
        const realGroupIndex = indexChangingIcon * 2;
        copy.groups.content[realGroupIndex].image = newIconName;
        copy.staff.content[indexChangingIcon*2+3].image = newIconName;
        setData(copy);
        setIndexChangingIcon(-1);
    }

    const drawChangeIcon = () => {
        return icons.map((el, index) => (
            <img key={index} onClick={setNewIcon.bind(this, el)} className="changeImg" src={`/images/grupy/${el}`} alt=''></img>
        ));
    };

    const drawGroups = () => {
        const groups = data.groups.content.filter(el => el.type === 'section');
        return groups.map((group, index) => (
            <div className="group" key={index}>
                {index !== indexChangingIcon ? drawIcon(true, group.image, index) : <div className={'icons'}>{drawChangeIcon()}</div>}
                Nazwa grupy:
                <input size="20"
                       value={data.staff.content[index*2+3].content[0]}
                       type="text"
                       onChange={(el) => changeGroupName(el.target.value, index*2+3)}>
                </input>
                <br/>
                Nazwa grupy z opisem:
                <input size="30"
                       value={group.content[0]}
                       type="text"
                       onChange={(el) => changeGroupNameWithDesc(el.target.value, index)}>
                </input>
                <div>Wychowawcy:</div>
                {drawStaff(index*2+4)}
                {index !== indexAddingPhoto ? drawPhoto(index*2+1) : ''}
                {drawChangePhoto(index)}
                <br/>
                <br/>
            </div>
        ));
    }

    const drawPhoto = (index) => {
        return <img className="photo" src={`/zdjecia/grupy/${data.groups.content[index].items[0].src}`} alt=''></img>
    }

    const drawPrincipal = () => {
        return (
            <div>
                <input size="40" value={data.staff.content[1].content[0]}
                       type="text" onChange={(el) => changePrincipalName(el.target.value)}>
                </input>
            </div>
        );
    }

    const drawAdditionals = () => {
        const sections = data.staff.content.filter(el => el.isAdditional);

        return sections.map((el) => {
            const index = data.staff.content.findIndex(elt => elt.id === el.id && !elt.isAdditional);
            return (
                <div className="group" key={el.id}>
                    <div>{el.content[0]}</div>
                    {drawStaff(index)}
                    <button onClick={() => removeAdditional(el.id)}>Usuń</button>
                </div>
            )
        })
    }

    const removeAdditional = (id) => {
        const copy = {...data};
        copy.staff.content = data.staff.content.filter(el => el.id !== id);
        setData(copy);
    }

    return (
        <div className="editGroups">
            <div>Dyrektor:</div>
            {drawPrincipal()}
            <br/>
            <div>Grupy:</div>
            <div className="groupHolder">
                {drawGroups()}
                {drawAdditionals()}
            </div>
        </div>
    )
}

export default EditGroups;