import {useCallback, useState} from 'react';
import './editParents.scss';
import _ from 'lodash';
import AddContent from "./AddContent";

function EditParents({data, setData}) {
    const [editForm, setEditForm] = useState(false);
    const [addingNew, setAddingNew] = useState(false);
    const [articleCopy, setArticleCopy] = useState({});
    const [editingArticleId, setEditingArticleId] = useState(null);
    const [editingArticleIndex, setEditingArticleIndex] = useState(null);
    const [content, setContent] = useState([]);

    const allowedList = {
        text: true,
        paragraph: true,
        bullets: true
    };

    const editArticle = useCallback((ev, id) => {
        ev.preventDefault();
        setArticleCopy(_.cloneDeep(data.parents.find(el => el.id === id)));
        const index = data.parents.findIndex(el => el.id === id);
        setEditingArticleIndex(index);
        setContent(_.cloneDeep(data.parents[index].content));
        setEditForm(true);
    }, [data, setData, setEditForm, setAddingNew, setEditingArticleId]);

    const cancel = useCallback(() => {
        const dataCopy = _.cloneDeep(data);
        if (addingNew) {
            dataCopy.parents.splice(editingArticleIndex, 1);
            setAddingNew(false);
        } else {
            if (editingArticleIndex >= 0 ) {
                dataCopy.parents[editingArticleIndex] = _.cloneDeep(articleCopy);
                setArticleCopy({});
            }
        }
        setData(dataCopy);
        setContent([]);
        setEditingArticleIndex(-1);
        setEditForm(false);
        setEditingArticleId(null);
    }, [data, editForm, addingNew, editingArticleId, editingArticleIndex]);

    const removeArticle = useCallback(() => {
        if (window.confirm("Czy na pewno chcesz usunąć ten wpis?") !== true) {
            return;
        }
        const dataCopy = _.cloneDeep(data);
        dataCopy.parents.splice(editingArticleIndex, 1);
        setData(dataCopy);
        setEditingArticleIndex(-1);
        setEditForm(false);
        setContent([]);
        setEditingArticleId(null);
    }, [data, editForm, addingNew, editingArticleId, editingArticleIndex])

    const saveArticle = useCallback(() => {
        if (addingNew) {
            setAddingNew(false);
        } else {

        }
        const dataCopy = _.cloneDeep(data);
        dataCopy.parents[editingArticleIndex].content = content;
        setData(dataCopy);
        setContent([]);
        setEditingArticleIndex(-1);
        setEditForm(false);
        setEditingArticleId(null);
    }, [data, content, editForm, addingNew, editingArticleId, editingArticleIndex]);

    const addNewArticle = useCallback(() => {
        const newId = `${Math.max(...data.parents.map(el => parseInt(el.id))) + 1}`;
        const newEntry = {
            type: 'article',
            id: newId,
            link: 'Przeczytaj',
            linkHide: 'Ukryj',
            styles: 'justify',
            author: '',
            title: '',
            content: []
        };
        const dataCopy = _.cloneDeep(data);
        dataCopy.parents.unshift(newEntry);
        setData(dataCopy);
        const index = dataCopy.parents.findIndex(el => el.id === newId);
        setEditingArticleIndex(index);
        setEditForm(true);
        setAddingNew(true);
        setEditingArticleId(newId);
    }, [data, setData, setEditForm, setAddingNew, setEditingArticleId]);

    const actionButtons = (
        <div>
            <button onClick={cancel}>Zrezygnuj z {addingNew ? 'dodawania' : 'edycji'} (cofnij)</button>
            {!addingNew && (<button onClick={removeArticle}>Usuń wpis</button>)}
            <button onClick={saveArticle}>Zapisz zmiane</button>
        </div>
    )

    return (
        <div className={'editParents'}>
        {!!editForm &&
            (
                <div>
                    {actionButtons}
                    <div><h2>{addingNew ? 'Dodawanie' : 'Edycja'} wpisu "Dla rodziców":</h2></div>
                    <div>
                        Tytuł:{' '}
                        <input
                            size="100"
                            value={data.parents[editingArticleIndex].title}
                            type="text"
                            onChange={(el) => {
                                const dataCopy = _.cloneDeep(data);
                                dataCopy.parents[editingArticleIndex].title = el.target.value;
                                setData(dataCopy);
                            }}
                        />
                    </div>
                    <div>
                        Dodaj treść:
                        <AddContent content={content} setContent={setContent} availableItems={allowedList} style={'justify'}></AddContent>
                    </div>

                    <div className="bottomButtons">
                        {actionButtons}
                    </div>
                </div>
            )
        }
        {
            !editForm && (
                <>
                    <div>
                        <button onClick={addNewArticle}>Dodaj nowy wpis</button>
                    </div>
                    <div>
                        <h2>Lista wpisów:</h2>
                        {data.parents.map(article => {
                            return (
                                <div key={`art${article.id}`}>
                                    <a
                                        href="#"
                                        onClick={(ev) => editArticle(ev, article.id)}
                                    >
                                        {article.title ? article.title : '\<Bez tytułu\>'}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </>
            )
        }
        </div>
    );
}

export default EditParents;