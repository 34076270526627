import moment from 'moment';
import {useState, useEffect} from 'react';
import AddContent from "./AddContent";


const AddNews = ({save, cancel}) => {
    const [content, setContent] = useState([]);
    const [news, setNews] = useState({
        type: 'news',
        id: Date.now(),
        styles: 'center',
        showOnMainPage: true,
        date: moment().format('YYYY-MM-DD'),
        content: [
            {
                type: 'section',
                content: ['']
            }
        ]
    });
    useEffect(() => {
        const copyNews = {...news}
        const copyTitle = copyNews.content[0];
        copyNews.content = [copyTitle, ...content];
        setNews(copyNews);
    }, [content]);

    const setTitle = (value) => {
        const newNews = {...news}
        newNews.content[0].content = [value];
        setNews(newNews);
    };

    const allowedList = {
        text: true
    };

    return (
        <div>
            <div>Dodawanie do 'Aktualności'</div>
            <div>
                Tytuł:
                <input type="text" onChange={(el) => setTitle(el.target.value)}></input>
            </div>

            <div>
                Dodaj treść:
                <AddContent content={content} setContent={setContent} availableItems={allowedList} style="center"></AddContent>
            </div>
            <button onClick={save.bind(this, news)}>Zapisz</button>
            <button onClick={cancel.bind(this)}>Anuluj</button>
        </div>

    );
}

export default AddNews;