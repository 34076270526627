const TYPES = {
    'LINK': 'LINK',
    'DROPDOWN_MENU': 'DROPDOWN_MENU',
    'DROPDOWN_ITEM': 'DROPDOWN_ITEM',
    'MOBILE_HAM': 'MOBILE_HAM',
    'MOBILE_MENU_ITEM': 'MOBILE_MENU_ITEM',
    'EXTERNAL_LINK': 'EXTERNAL_LINK',
    'MOBILE_MENU_ITEM_EXTERNAL': 'MOBILE_MENU_ITEM_EXTERNAL',
}

const navData = [
    {
        type: TYPES.LINK,
        name: 'Strona główna',
        href: '/',
        prevent: false,
        id: 'navMainPage',
        showMobile: false,
        showTablet: false,
        showPC: true,
        smallScreenIcon: '',
    },
    {
        type: TYPES.DROPDOWN_MENU,
        name: 'O nas',
        href: '/',
        prevent: true,
        showMobile: false,
        showTablet: true,
        showPC: true,
        id: 'navUs',
        smallScreenIcon: '',
        items: [
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Aktualności',
                href: '/news',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Projekty',
                href: '/projekty',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Nasze przedszkole',
                href: '/us',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Grupy',
                href: '/groups',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Wychowawcy',
                href: '/teachers',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Dokumenty przedszkola',
                href: '/documents',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Rekrutacja',
                href: '/recruitment',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Standardy ochrony małoletnich',
                href: '/standards',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.DROPDOWN_ITEM,
                name: 'Kontakt',
                href: '/contact',
                id: 'contact',
                prevent: false,
                smallScreenIcon: '',
            }
        ]
    },
    {
        type: TYPES.LINK,
        name: 'Z życia przedszkolaka',
        href: '/activity',
        id: 'navActivity',
        prevent: false,
        showMobile: false,
        showTablet: true,
        showPC: true,
        smallScreenIcon: '',
    },
    {
        type: TYPES.LINK,
        name: 'Dla rodziców',
        href: '/parents',
        id: 'navParents',
        prevent: false,
        showMobile: false,
        showTablet: true,
        showPC: true,
        smallScreenIcon: '',
    },
    {
        type: TYPES.LINK,
        name: 'Archiwum',
        href: '/archive',
        id: 'navArch',
        prevent: false,
        showMobile: false,
        showTablet: true,
        showPC: true,
        smallScreenIcon: '',
    },
    {
        type: TYPES.MOBILE_HAM,
        name: 'Menu',
        href: '/',
        id: 'mobile-menu',
        mobileOnly: true,
        showMobile: true,
        showTablet: false,
        showPC: false,
        prevent: true,
        smallScreenIcon: '',
        items: [
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Strona główna',
                href: '/',
                prevent: false,
                id: 'navMainPageMobile',
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Z życia przedszkolaka',
                href: '/activity',
                id: 'navActivityMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                id: 'newsMobile',
                name: 'Aktualności',
                href: '/news',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Projekty',
                id: 'thanksMobile',
                href: '/projekty',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Nasze przedszkole',
                href: '/us',
                id: 'usMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Grupy',
                href: '/groups',
                id: 'groupsMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Wychowawcy',
                href: '/teachers',
                id: 'teachersMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Dla rodziców',
                href: '/parents',
                id: 'navParentsMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Dokumenty przedszkola',
                href: '/documents',
                id: 'documentsMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Rekrutacja',
                href: '/recruitment',
                id: 'recMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Standardy ochrony małoletnich',
                href: '/standards',
                id: 'stdMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM_EXTERNAL,
                name: 'Archiwum',
                href: 'http://www.przedszkolestroza.pl/archive',
                id: 'navArchMobile',
                prevent: false,
                smallScreenIcon: '',
            },
            {
                type: TYPES.MOBILE_MENU_ITEM,
                name: 'Kontakt',
                href: '/contact',
                id: 'contactMobile',
                prevent: false,
                smallScreenIcon: '',
            }
        ]
    },
];

const result = {
    navData, TYPES
}

export default result;