import {Route, Switch} from "react-router-dom";
import LandingPage from "./landingPage/LandingPage";
import Articles from "./common/Articles";
import PageContent from "./common/PageContent";
import Teachers from "./pages/Teachers";
import Projects from "./pages/Projects";
import Groups from "./pages/Groups";
import Activities from "./pages/Activities";
import News from "./pages/News";
import AdminPanel from "./pages/AdminPanel";
import Archive from "./pages/Archive";

function AppRoutes({data, user, setUser, setData}) {
    return (
        <div className="content">
            {data ?
                <Switch>
                    <Route path="/news/:id?">
                        <News data={data.news}></News>
                    </Route>
                    <Route path="/projekty">
                        <Projects data={data.thanks}></Projects>
                    </Route>
                    <Route path="/us">
                        <PageContent data={data.us}></PageContent>
                    </Route>
                    <Route path="/groups">
                        <Groups data={data.groups}></Groups>
                    </Route>
                    <Route path="/teachers">
                        <Teachers data={data.staff}></Teachers>
                    </Route>
                    <Route path="/documents">
                        <PageContent data={data.documents}></PageContent>
                    </Route>
                    <Route path="/recruitment">
                        <PageContent data={data.recruitment}></PageContent>
                    </Route>
                    <Route path="/standards">
                        <PageContent data={data.standards}></PageContent>
                    </Route>
                    <Route path="/activity/:id?">
                        <Activities showHands={true} data={data.activity} path="activity"></Activities>
                    </Route>
                    <Route path="/parents">
                        <Articles title="Dla rodziców" articles={data.parents}></Articles>
                    </Route>
                    <Route path="/archive/:id?">
                        <Archive data={data.archive}></Archive>
                    </Route>
                    <Route path="/contact">
                        <PageContent data={data.contact}></PageContent>
                    </Route>
                    <Route path="/author">
                        <PageContent data={data.author}></PageContent>
                    </Route>
                    <Route path="/admin">
                        <AdminPanel user={user} setUser={setUser} data={data} setData={setData}></AdminPanel>
                    </Route>
                    <Route path="/">
                        <LandingPage
                            data={data.news}
                            lastArticle={data.activity.content[data.activity.content.length - 1]}>
                        </LandingPage>
                    </Route>
                </Switch>
                : ''}
        </div>
    )
};

export default AppRoutes;