import {useEffect, useState} from 'react';
import service from '../services/service';
import moment from 'moment';
import './editReverse.scss';

function EditReverse({data, setData, user}) {
    const [folders, setFolders] = useState([]);
    const [itemsNo, setItemsNo] = useState(3);
    const [reversed, setReversed] = useState(false);

    useEffect(() => {
        service.getRetrievalData(user.token, setFolders);
    }, []);

    const drawBackups = () => {
        return folders.slice().reverse().slice(0, itemsNo).map((el, index) => {
            const date = moment(Number.parseInt(el));
            return (<div key={index} className="row">
                {`${date.format('YYYY-MM-DD')}  godz. ${date.format('HH:mm')}`}
                <button onClick={reverseChanges.bind(this, el)}>Cofnij do wersji przed tą datą</button>
            </div>);
        });
    }

    const reverseChanges = (date) => {
        service.reverseData(user.token, date, setReversed);
    }

    const readMore = (ev) => {
        ev.preventDefault();
        setItemsNo(itemsNo => itemsNo + 5);
    }

    return (<div className="editReverse">
        {reversed ?
            <div>Zmiany zostały cofnięte</div>
            :
        <div>
            Uwaga! Wszystkie zmiany które zostały opublikowane od wersji do której cofamy zostaną utracone.<br/><br/>
            Ostatnie zmiany:
            {drawBackups()}
            {itemsNo < folders.length ?
                <a href="/" onClick={ev => readMore(ev)}>Wczytaj więcej historii</a>
                : ''}
        </div>
        }
    </div>);
}

export default EditReverse