import {useEffect} from 'react';
import './Gallery.scss';

function Gallery({folder, files, id}) {

    useEffect(() => {
        setTimeout(() => {
            window.jQuery(`#${id}`).nanogallery2({
                thumbnailHeight: `${files.length > 1 ? '200 XS100 ' : '330 XS170'}`,
                thumbnailWidth: `${files.length > 1 ? '300 XS120 ' : '600 XS300'}`,
                thumbnailHoverEffect2: "scale120",
                galleryMaxRows: 1,
                galleryDisplayMode : 'rows',
                itemsBaseURL: folder,
                thumbnailAlignment: 'center',
                thumbnailGutterWidth: 6,
                thumbnailSliderDelay: 99999,
                locationHash: false,
                items: files
            });
        }, 0);
    });

    return (
        <div className="gallery" id={id}></div>
    )
}

export default Gallery