import moment from "moment";
import './EditArchive.scss';

function EditArchive({data, setData}) {

    const getYearFormatted = (year) => {
        return `${year.slice(0,4)}/${year.slice(4)}`
    }

    const getNewYear = () => {
        if (moment().month() >= 8) {
            return `${moment().year()}${moment().year()+1}`;
        } else {
            return `${moment().year()-1}${moment().year()}`;
        }
    };

    const startNewYear = () => {
        const copy = {...data};
        copy.activity.currentYear = getNewYear();
        copy.activity.content = [];
        setData(copy);
    }

    const moveToArchive = () => {
        const copy = {...data};
        copy.archive.data = [{
            title: getYearFormatted(data.activity.currentYear),
            year: data.activity.currentYear,
            content: [{
                title: `Archiwum ${getYearFormatted(data.activity.currentYear)}`,
                currentYear: data.activity.currentYear,
                content: data.activity.content
            }]
        }, ...data.archive.data];
        setData(copy);
    }

    return (<div className="editArchive">
        <div>Ostatni zarchiwowany rok: <b>{data.archive.data[0].title}</b></div>
        <div>
            Artukuły w "Z życia przedszkolaka" są z roku: <b>
                {getYearFormatted(data.activity.currentYear)}
        </b>
        </div>
        <br/>
        {data.activity.currentYear === data.archive.data[0].year ?
            <button onClick={startNewYear}>Usuń stare artykuły z "Ż życia przedszkolaka (zacznij nowy rok)"</button> : ''}
        {data.activity.currentYear !== data.archive.data[0].year ?
            <button onClick={moveToArchive}>Zakończenie roku - wyślij wpisy "Z życia przedszkolaka" do "Archiwum"</button> : ''}
    </div>);
}

export default EditArchive