import './Utils.scss'
import Gallery from "../common/Gallery";

const modifySingleChars = str => str.replace(/ ([a-zA-Z]) /g,
    ' $1' + '\u00A0');

const displayBullets = (content, styles) => {
    if (typeof content === 'object') {
        return content.map((item, index) => {
            return (
                <p key={index} className={styles}>
                    <span>&bull; {modifySingleChars(item)}</span><br/>
                </p>
            );
        });
    } else {
        return <p>&bull; {content}</p>
    }
}

const displayText = (items) => {
    if (typeof items.content === 'object') {
        return items.content.map((item, index) => {
            return (
                <p className={items.styles} key={index}>
                    <span>{modifySingleChars(item)}</span><br/>
                </p>);
        });
    } else {
        return <p className={`paragraph ${items.styles}`}>&nbsp;&nbsp;&nbsp;&nbsp;{modifySingleChars(items.content)}</p>
    }
}

const displayTextWithLink = (items) => {
    if (typeof items.content === 'object') {
        let result = items.content.map((item, index) => {
            if (item.includes('LINK__')) {
                return <a target="_blank" rel="noreferrer" key={index} href={item.slice(6)}>{item.slice(6)}</a>
            } else {
                return <span key={index}>{modifySingleChars(item)}</span>
            }
        });
        return <p className="paragraph">{result}</p>;
    } else {
        if (items.content.includes('LINK__')) {
            return <a target="_blank" rel="noreferrer"  href={items.content.slice(6)}>{items.content.slice(6)}</a>
        } else {
            return <span>{modifySingleChars(items.content)}</span>
        }
    }
}

const generateItem = ((item, index) => {
    if (item.type === 'textWithLink') {
        return (
            <div className={`${item.style} 'text-with-link'`} key={index}>{displayTextWithLink(item)}</div>
        );
    }
    if (item.type === 'paragraph') {
        return (
            <div key={index}>{displayText(item)}</div>
        );
    }
    if (item.type === 'text') {
        return (
            <div key={index}>{displayText(item)}</div>
        );
    }
    if (item.type === 'bullets') {
        return (
            <div key={index} className="bullets">
                {displayBullets(item.content, item.styles)}
            </div>
        )
    }
    if (item.type === 'section') {
        return (
            <h3 key={index} className={item.styles}>{item.content}</h3>
        )
    }
    if (item.type === 'document') {
        return (
            <div key={index} className={`document ${item.styles}`}>
                <span>{item.name}</span>
                <a download href={`./documents/${item.fileName}`}>Pobierz</a>
            </div>
        );
    }
    if (item.type === 'gallery') {
        return (
            <Gallery key={index} files={item.items} folder={item.folder} id={item.id}></Gallery>
        )
    }
    if (item.type === 'photo') {
        return (
            <img src={`${item.folder}/${item.items[0].src}`} />
        );
    }
    return (
        <div key={index}></div>
    );
});

const result = {
    generateItem: generateItem
}

export default result
