import './GenerateContent.scss';
import {useEffect} from 'react';
import generatorUtils from '../utils/GeneratorUtils';
import $ from 'jquery';

function GenerateContent({id, contentToGenerate, showArticle}) {
    useEffect(() => {
        if (showArticle) {
            $(`#article_${id}`).slideDown(2500);
        } else if (showArticle === false) {
            $(`#article_${id}`).slideUp(1000);
        }
    }, [showArticle, id]);
    const result = contentToGenerate.map((item, index) => {
        if (item.type === '2-columns') {
            return (
                <div key={index} className="columns">
                    <div className="col">
                        {item.firstCol.content.map((fcItem, fcIndex) => {
                            return generatorUtils.generateItem(fcItem, fcIndex);
                        })}
                    </div>
                    <div className="col">
                        {item.secondCol.content.map((fcItem, fcIndex) => {
                            return generatorUtils.generateItem(fcItem, fcIndex);
                        })}
                    </div>
                </div>
            )
        }
        return (
            generatorUtils.generateItem(item, index)
        )
    });

    return (
        <div id={`article_${id}`} className={`generatedContent ${showArticle !== undefined ? 'hidden' : ''}`}>{result}</div>
    );
};

export default GenerateContent;