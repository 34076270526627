import './Articles.scss';
import ArticleBox from "./ArticleBox";

function Articles({title, articles}) {

    const articleBoxes = articles.map((article, index) => <ArticleBox key={index} article={article}></ArticleBox>);

    return (
        <div className="articles">
            <h1 className="title">{title}</h1>
            <div className="list">
                {articleBoxes}
            </div>
        </div>
    );
}

export default Articles;