import "./Header.scss";
import {Link} from "react-router-dom";
import Navigation from "./Navigation";
import {useState} from 'react';

function Header({user}) {
    const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);
    return (
        <div>
            <div className={`header sticky`}>
                <Link className="logo" to="/">
                    <img className="hide-tablet" src="/images/kindergarten.svg" alt="Strona główna"></img>
                    Przedszkole w Stróży
                </Link>
                {user.loggedIn ? <Link to="/admin">Jesteś zalogowana, przejdź do Administracji</Link> : ''}
                <div className="flexRight">
                    <Navigation isMobileMenuShown={isMobileMenuShown} setIsMobileMenuShown={setIsMobileMenuShown} showMobile={false}></Navigation>
                </div>
            </div>
            <Navigation isMobileMenuShown={isMobileMenuShown} setIsMobileMenuShown={setIsMobileMenuShown} showMobile={true}></Navigation>
        </div>
    )
}

export default Header