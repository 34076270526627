import './archive.scss';
import {useState} from 'react';
import Activities from "./Activities";
import {useHistory } from 'react-router-dom';

function Archive({data}) {
    let history = useHistory();
    const [yearSelected, setYearSelected] = useState(true);
    const [year, setYear] = useState(data.data[0]);

    const showArchive = (el, event) => {
        event.preventDefault();
        setYear(el);
        setYearSelected(true);
    }

    const showList = (event) => {
        event.preventDefault();
        setYear({});
        setYearSelected(false);
        history.push({
            pathname: `/archive/`
        });
    }

    const goto = (el) => {
        window.location.href=el.externalUrl;
    }

    const showEl = (el) => {
        if (el.externalUrl) {
            return (
                <button className="showAllYears" onClick={goto.bind(this, el)}>
                    <span>&#x205D;&#x205D;&#x205D;</span> {el.title} (stara strona) <span>&#x205D;&#x205D;&#x205D;</span>
                </button>
            )
        } else {
            return (
            <button className="showAllYears" onClick={showArchive.bind(this, el)}>
                <span>&#x205D;&#x205D;&#x205D;</span> {el.title} <span>&#x205D;&#x205D;&#x205D;</span>
            </button>
            )
        }
    }

    const showYears = () => {
        return data.data.map((el, index) =>
            <div className="item" key={index}>
                {showEl(el)}
            </div>
        );
    };

    return (
        <div className="archive">
            <div className="center item">
                {yearSelected ?
                    <button className="showAllYears" onClick={showList.bind(this)}>
                        <span>&#x205D;&#x205D;&#x205D;</span> Poprzednie lata <span>&#x205D;&#x205D;&#x205D;</span>
                    </button>
                    : ''}
            </div>
            {!yearSelected ? <div className="center">{showYears()}</div> : <Activities data={year.content[0]} isArch={true} path="archive"></Activities>}
        </div>
    )
}

export default Archive;