import _ from 'lodash';
import './addBullet.scss';
function AddBullets({item, setItem, index}) {

    const updateBullets = (value, i) => {
        const clone = _.cloneDeep(item);
        clone.content[i] = value;
        setItem(index, clone);
    }

    const addBullet = () => {
        const clone = _.cloneDeep(item);
        clone.content.push('');
        setItem(index, clone);
    }

    const removeDot = (i) => {
        const clone = _.cloneDeep(item);
        clone.content.splice(i, 1);
        setItem(index, clone);
    };

    return (
        <div className={`addBullet`}>
            <div>
                <div>{`Lista wypunktowana (${index + 1})`}</div>
                {item.content.map((bulletContent, indexInternal) => {
                    return (
                        <div key={`bullet${indexInternal}`}>
                            <textarea
                                rows={Math.ceil(item.content[indexInternal].length/80)}
                                cols="90"
                                onChange={(ev) => updateBullets(ev.target.value, indexInternal)}
                                value={item.content[indexInternal]}>
                            </textarea>
                            <button onClick={() => removeDot(indexInternal)}>Usuń tą kropkę</button>
                        </div>
                    );
                })}
                <div>
                    <button onClick={addBullet}>Dodaj kolejną kropkę w liście</button>
                </div>
            </div>
        </div>);
}

export default AddBullets;